// @flow
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { NegativeMessageContainer } from '@presentational/messages/NegativeMessageContainer';
import { StyledHeader } from '@src/common-utils/styledComponents';
import React from 'react';
import { useParams } from 'react-router-dom';

const SPPricingSummaryContainer = ({ data, children, useRecalcRequestMutation }) => {
  const params = useParams();

  const [submitRecalcRequest] = useRecalcRequestMutation();
  const status = data.status;

  if (status === 'failed') {
    return (
      <PaddedAndRaisedSegment disabled={false}>
        <PrimaryButton
          onClick={() => {
            submitRecalcRequest({ ...params });
          }}
          text="Re-Calculate Now!"
        />
        <NegativeMessageContainer
          body={
            <p>
              Please try again by clicking the button above. If it persists, please contact
              #cloud-commitment-estimator channel
            </p>
          }
          header="An Unexpected Error Occurred"
        />
      </PaddedAndRaisedSegment>
    );
  }

  if (status === 'pending') {
    return (
      <PaddedAndRaisedSegment disabled={false}>
        <PrimaryButton
          onClick={() => {
            submitRecalcRequest({ ...params });
          }}
          text="Re-Calculate Now!"
        />
        <StyledHeader style={{ color: 'red' }}>
          You have made some edits! Please click on Re-Calculate Now to fetch latest data!
        </StyledHeader>
      </PaddedAndRaisedSegment>
    );
  }

  if (status === 'in progress' || status === 'queued') {
    return (
      <PaddedAndRaisedSegment disabled={false}>
        <PrimaryButton disabled={true} text="Re-Calculate Now!" />
        <StyledHeader style={{ color: 'red' }}>
          Note: Repricing is still happening! Please wait while the reprice finishes. You can
          refresh the page periodically to get the latest status.
        </StyledHeader>
      </PaddedAndRaisedSegment>
    );
  }

  if (status === 'success') {
    return children;
  }
};

export default SPPricingSummaryContainer;
