// @flow
import React, { useContext } from 'react';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { getSPCommitDetailsBreadcrumbs } from '@streaming-projects/utils';

import { useSPCommitDetailsContext } from './context/SPCommitDetailsContext';

const SPCommitDetailsBreadcrumbs = () => {
  const spOrgContext = useContext(SPOrgContext);
  const spCommitDetailsContext = useSPCommitDetailsContext();

  return (
    <SecondaryTopBar
      sectionsToShow={getSPCommitDetailsBreadcrumbs(spOrgContext, spCommitDetailsContext)}
      showLastUpdatedMessage={false}
    />
  );
};

export default SPCommitDetailsBreadcrumbs;
