// @flow

import React, { useContext, useState } from 'react';
import {
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { useAddClusterWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { handleGridRowSelectionChanged } from '@src/common-utils/utils';
import { useHistory, useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { toastError } from '@presentational/notifications/utils';
import { getLinkForStreamingProjectsClusterWorkloadsPage } from '@streaming-projects/links';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import {
  getMappedDataValue,
  getMappedDataValueGetter,
} from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';
import {
  PercentageRenderer,
  ResourceSelectionRadioButtonRenderer,
} from '@components/views/grid-utils/renderers';
import { ADD_NEW_WORKLOAD_MESSAGE, NO_FREE_RESOURCE_MESSAGE } from '@streaming-projects/constants';

export const SPClusterWorkloadAddModal = ({ isOpen, setOpen }) => {
  const { orgId, spId } = useParams();
  const spOrgData = useContext(SPOrgContext);
  const { providerRegionsMap, resourceConfigurationsNameLabelsMap, providerNameLabelMap } =
    useContext(SPMetaDataContext).metaData;

  const [addClusterWorkload] = useAddClusterWorkloadMutation();
  const { push } = useHistory();

  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const alreadyConfiguredWorkloads =
    spStreamingProjectData?.use_case_summaries?.clusters_summaries ?? [];
  const setOfAlreadyUsedResources = new Set(alreadyConfiguredWorkloads.map((x) => x.resource_id));
  const completeListOfConfiguredResources = spOrgData?.resources?.cluster_resources ?? [];
  const freeResourcesWhichAreStillAvailable = completeListOfConfiguredResources.filter(
    (resource) => !setOfAlreadyUsedResources.has(resource.id)
  );

  const columnDefs = [
    {
      field: 'Selection',
      sortable: false,
      filter: false,
      cellRenderer: (props) =>
        ResourceSelectionRadioButtonRenderer(props, selectedRow, CLUSTER_RESOURCES_GRID_HEADERS.ID),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NAME,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.TYPE,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE]
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NETWORKING_TYPE,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE]
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      valueGetter: getMappedDataValueGetter(providerNameLabelMap),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.REGION,
      tooltipField: CLUSTER_RESOURCES_GRID_HEADERS.REGION,
      valueGetter: (params) =>
        getMappedDataValue(params, providerRegionsMap[params.data.Provider].regions),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.AVAILABILITY,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY,
      valueGetter: getMappedDataValueGetter(
        resourceConfigurationsNameLabelsMap[CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY]
      ),
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.SLA,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.SLA,
      cellRenderer: PercentageRenderer,
    },
  ];

  const onSelectionChanged = handleGridRowSelectionChanged(
    (rowData) => {
      setSelectedRow(rowData);
    },
    () => setSelectedRow(null)
  );

  return (
    <StyledContainer>
      <ConfirmModal
        body={
          <StyledContainer>
            <DataGrid
              columnDefs={columnDefs}
              inputDataSource={freeResourcesWhichAreStillAvailable}
              noDataMessage={NO_FREE_RESOURCE_MESSAGE}
              onSelectionChanged={onSelectionChanged}
              rowSelection="single"
              sizeColumnsToFitInitially={true}
              tooltipShowDelay={0}
            />
          </StyledContainer>
        }
        cancelButtonNegative={true}
        disabled={!selectedRow}
        fullScreen={true}
        header={ADD_NEW_WORKLOAD_MESSAGE}
        isOpen={isOpen}
        okButtonNegative={false}
        okButtonText="Continue"
        onClickHandlerForCancel={() => {
          setSelectedRow(null);
          setOpen(false);
        }}
        onClickHandlerForOK={async () => {
          const { error, data } = await addClusterWorkload({
            orgId,
            spId,
            payload: {
              name: 'Default',
              cluster_resource_id: selectedRow[CLUSTER_RESOURCES_GRID_HEADERS.ID],
              sp_version: spStreamingProjectData?.sp?.version,
            },
          });
          if (error) {
            toastError(error);
          } else {
            push(getLinkForStreamingProjectsClusterWorkloadsPage(orgId, spId, data.id));
          }
          setOpen(false);
        }}
      />
    </StyledContainer>
  );
};
