// @flow
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { useGetSPCommitDetailsQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import SPCommitDetailsContext from './SPCommitDetailsContext';

const SPCommitDetailsContextProvider = ({ children }) => {
  const params = useParams();

  const dataFetchingArgs = useMemo(
    () => [{ ...params }, { skip: shouldSkipDataFetching(params) }],
    [params]
  );

  const dataFetchingFunction = useCallback(useGetSPCommitDetailsQuery, []);

  return (
    <DataFetcherContainer
      dataFetchingArgs={dataFetchingArgs}
      dataFetchingFunction={dataFetchingFunction}
    >
      {(data) => (
        <SPCommitDetailsContext.Provider value={data}>{children}</SPCommitDetailsContext.Provider>
      )}
    </DataFetcherContainer>
  );
};

export default SPCommitDetailsContextProvider;
