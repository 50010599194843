// @flow
import {
  STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES,
  STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { string } from 'yup';
import { convertConfigArrayToMap } from '@src/configuration/utils';

export const STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG = [
  {
    displayName: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ENVIRONMENT_NAME,
    backendFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.ENVIRONMENT_NAME,
    validation: string()
      .label(STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ENVIRONMENT_NAME)
      .min(1)
      .max(200)
      .required()
      .matches(
        /^[a-zA-Z0-9_]+$/,
        'Only alphabets, numbers and underscores are allowed in Environment Name '
      ),
  },
  {
    displayName: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PACKAGE_NAME,
    backendFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PACKAGE_NAME,
    dependentFields: [
      STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION,
    ],
    validation: string().label(STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PACKAGE_NAME).required(),
  },
  {
    displayName: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PROVIDER,
    backendFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER,
    displayNameCol: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER_LABEL,
    validation: string()
      .required()
      .label(STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PROVIDER)
      .nullable(),
  },
  {
    displayName: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.REGION,
    backendFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION,
    validation: string()
      .required()
      .label(STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.REGION)
      .nullable(),
    parentFieldsInGroup: [
      STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PACKAGE_NAME,
      STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER,
    ],
    displayNameCol: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION_LABEL,
  },
];

export const STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG
);
