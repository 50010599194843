// @flow

import React, { useContext, useState } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPFlinkWorkloadAddModal } from '@streaming-projects/sp-page/workloads-summary/flink-workloads-summary/SPFlinkWorkloadAddModal';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import {
  FLINK_POOL_RESOURCES_GRID_FIELDNAMES,
  FLINK_POOL_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { SPFlinkWorkloadNameRenderer } from '@streaming-projects/sp-page/workloads-summary/flink-workloads-summary/SPFlinkWorkloadNameRenderer';
import { SPFlinkWorkloadDeleteRenderer } from '@streaming-projects/sp-page/workloads-summary/flink-workloads-summary/SPFlinkWorkloadDeleteRenderer';
import { ENABLED_DISABLED_FILTER_PARAMS } from '@components/views/grid-utils/constants';
import { SPFlinkWorkloadEnabledCellRenderer } from '@streaming-projects/sp-page/workloads-summary/flink-workloads-summary/SPFlinkWorkloadEnabledCellRenderer';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import {
  getMappedDataValue,
  getMappedDataValueGetter,
} from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';
import { ADD_NEW_WORKLOAD_MESSAGE } from '@streaming-projects/constants';

export const SPFlinkSPPageWorkloadSummaryContainer = () => {
  const { providerRegionsMap, providerNameLabelMap } = useContext(SPMetaDataContext).metaData;
  const [isFlinkWorkloadAddModalOpen, setFlinkWorkloadAddModalOpen] = useState(false);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const inputDataSource = spStreamingProjectData?.use_case_summaries?.flink_summaries ?? [];
  const areAnyFlinkWorkloadsConfigured = inputDataSource.length > 0;

  const columnDefs = [
    {
      field: FRONT_END_DISPLAY_NAMES.WORKLOAD_ID,
      backEndFieldName: BACKEND_FIELDNAMES.WORKLOAD_ID,
      hide: true,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.WORKLOAD_NAME,
      backEndFieldName: BACKEND_FIELDNAMES.WORKLOAD_NAME,
      cellRenderer: SPFlinkWorkloadNameRenderer,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.RESOURCE_ID,
      backEndFieldName: BACKEND_FIELDNAMES.RESOURCE_ID,
      hide: true,
    },
    {
      field: FLINK_POOL_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: BACKEND_FIELDNAMES.RESOURCE_NAME,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.IS_ENABLED,
      filter: true,
      filterParams: ENABLED_DISABLED_FILTER_PARAMS,
      cellRenderer: SPFlinkWorkloadEnabledCellRenderer,
      backEndFieldName: BACKEND_FIELDNAMES.IS_ENABLED,
    },
    {
      field: FLINK_POOL_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      valueGetter: getMappedDataValueGetter(providerNameLabelMap),
    },
    {
      field: FLINK_POOL_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.REGION,
      tooltipField: FLINK_POOL_RESOURCES_GRID_HEADERS.REGION,
      valueGetter: (params) =>
        getMappedDataValue(params, providerRegionsMap[params.data.Provider].regions),
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPFlinkWorkloadDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setFlinkWorkloadAddModalOpen(true);
        }}
        size="mini"
        text={ADD_NEW_WORKLOAD_MESSAGE}
      />

      <SPFlinkWorkloadAddModal
        isOpen={isFlinkWorkloadAddModalOpen}
        setOpen={setFlinkWorkloadAddModalOpen}
      />

      {areAnyFlinkWorkloadsConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Flink Workloads Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
