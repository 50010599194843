// @flow
import React from 'react';

const MappedDataRenderer = ({ value, mapping }) => {
  let renderValue = value;
  if (mapping?.[value]) {
    renderValue = mapping[value];
  }
  return <>{renderValue}</>;
};

export const getMappedDataValue = (params, mapping) => {
  const { data, column } = params;
  const value = data?.[column.colId];

  let mappedValue = value;
  if (mapping?.[value]) {
    mappedValue = mapping[value];
  }
  return `${mappedValue}`;
};

/**
 * Takes a mapping object and returns a function that can be used as a valueGetter in ag-grid columnDefs
 * that will map the value of the column to the mapped value in the mapping object
 */
export const getMappedDataValueGetter = (mapping) => (params) => {
  return getMappedDataValue(params, mapping);
};

export default MappedDataRenderer;
