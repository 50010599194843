// @flow
import React from 'react';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Formik } from 'formik';
import { PromptIfFormHasUnSavedChanges } from '@presentational/PromptIfFormHasUnSavedChanges';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { useSPCommitDetailsContext } from '@streaming-projects/commit-details/context/SPCommitDetailsContext';

import { SP_COMMIT_DETAILS_CONFIG } from './config';
import SPCommitDetailsAccordions from './accordions-container/SPCommitDetailsAccordions';

const SPCommitDetailsTopLevelContainerForm = () => {
  const spCommitDetailsContext = useSPCommitDetailsContext();

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      SP_COMMIT_DETAILS_CONFIG,
      spCommitDetailsContext
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {() => (
          <Form autoComplete="off">
            <PromptIfFormHasUnSavedChanges />
            <SPCommitDetailsAccordions />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};

export default SPCommitDetailsTopLevelContainerForm;
