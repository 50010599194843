// @flow
import React, { useContext, useState } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPStreamGovernanceConfigurationAddEditModal } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/SPStreamGovernanceConfigurationAddEditModal';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import {
  STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES,
  STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { SPStreamGovernanceResourceDeleteRenderer } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/SPStreamGovernanceResourceDeleteRenderer';
import { StreamGovernanceResourceEditRenderer } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/StreamGovernanceResourceEditRenderer';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

import {
  getMappedDataValue,
  getMappedDataValueGetter,
} from '../auxilary-components/MappedDataRenderer';

export const SPStreamGovernanceResourcesSummaryContainer = () => {
  const spOrgData = useContext(SPOrgContext);
  const { providerRegionsMap, providerNameLabelMap } = useContext(SPMetaDataContext).metaData;
  const [isSPStreamGovernanceConfigAddEditModalOpen, setSPStreamGovernanceConfigAddEditModalOpen] =
    useState(false);

  const columnDefs = [
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ENVIRONMENT_NAME,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.ENVIRONMENT_NAME,
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PACKAGE_NAME,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PACKAGE_NAME,
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      valueGetter: getMappedDataValueGetter(providerNameLabelMap),
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION,
      tooltipField: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.REGION,
      valueGetter: (params) =>
        getMappedDataValue(params, providerRegionsMap[params.data.Provider].regions),
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.VERSION,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.VERSION,
      hide: true,
    },
    {
      field: '',
      filter: false,
      cellRenderer: StreamGovernanceResourceEditRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPStreamGovernanceResourceDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  const inputDataSource = spOrgData?.resources?.sg_resources ?? [];
  const areAnyStreamGovernanceResourcesConfigured = inputDataSource.length > 0;

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setSPStreamGovernanceConfigAddEditModalOpen(true);
        }}
        size="mini"
        text="Add Stream Governance Shared Resource Configuration"
      />

      <SPStreamGovernanceConfigurationAddEditModal
        isOpen={isSPStreamGovernanceConfigAddEditModalOpen}
        setOpen={setSPStreamGovernanceConfigAddEditModalOpen}
      />

      {areAnyStreamGovernanceResourcesConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          displayResizingColsOption={true}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Stream Governance Resources Configuration Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};

/*

SG BE Feedback:

    Need SG_PARAMS_HACK, in that specific format
  //////// NEW ///////
 // todo::SP-Package OneOf should use small letters , otherwise update will fail, unless backend is consistent in get. Also, should be consistent with other places like Flink

 */
