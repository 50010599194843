// @flow
import { array, object, string } from 'yup';
import { getIn } from 'formik';
import { cloneDeep } from 'lodash';

import {
  CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
  DEFAULT_VALUE_FOR_DB_ROW_ID_STR,
} from '../../../constants';
import { getMonthlyInputsValidationFuncForType } from '../cluster-details-inputs/kafka-monthly-inputs-validation';
import { toastError } from '../../presentational/notifications/utils';

export const getMonthName = (i) => `Month ${i}`;
export const getYearName = (i) => `Year ${i}`;

export const getStartAndEndMonthsForGivenYear = (selectedYear) => {
  const startMonthNumber = 12 * (selectedYear - 1) + 1;
  const endMonthNumber = 12 * (selectedYear - 1) + 12;
  return { startMonthNumber, endMonthNumber };
};

export const getRelevantMonthsBasedOnSelectedYear = (selectedYear, allMonths) => {
  const monthsToRet = [];
  const { startMonthNumber, endMonthNumber } = getStartAndEndMonthsForGivenYear(selectedYear);

  for (let i = startMonthNumber; i <= endMonthNumber; i++) {
    if (Object.prototype.hasOwnProperty.call(allMonths, getMonthName(i))) {
      monthsToRet.push(allMonths[getMonthName(i)]);
    }
  }

  return monthsToRet;
};

export const addAdditionalDummyMonthsIfRequired = (selectedYear, months) => {
  const { startMonthNumber, endMonthNumber } = getStartAndEndMonthsForGivenYear(selectedYear);
  const monthNamesPassed = months.map((x) => x.name);
  const monthsToUse = [...months];

  for (let i = startMonthNumber; i <= endMonthNumber; i++) {
    if (!monthNamesPassed.includes(getMonthName(i))) {
      monthsToUse.push({
        name: getMonthName(i),
        isMonthArtificiallyGenerated: true,
      });
    }
  }
  return monthsToUse;
};

export const getDownloadColumns = (dataInFormik) => {
  const downloadColumns = [{ id: 'Input', name: 'Input' }];
  for (const [monthName] of Object.entries(dataInFormik)) {
    downloadColumns.push({
      id: monthName,
      name: monthName,
    });
  }
  return downloadColumns;
};

export const getDownloadData = (
  rowsConfig,
  dataInFormik,
  values,
  contextData,
  addCalendarMonthRowToDownload = false
) => {
  const dataToDownload = [];
  const rowsConfigToUse = [...rowsConfig];
  if (addCalendarMonthRowToDownload) {
    rowsConfigToUse.unshift({
      displayName: 'Calendar Month',
      backendName: 'calendar_month',
    });
  }
  for (const row of rowsConfigToUse) {
    const shouldDownloadRow = !row.showOrHideFunc ? true : row.showOrHideFunc(values, contextData);
    if (!shouldDownloadRow) {
      continue;
    }
    const currRowObj = {};
    currRowObj.Input = row.displayName;
    // Month-wise Data
    for (const [monthName, monthData] of Object.entries(dataInFormik)) {
      currRowObj[monthName] = monthData[row.backendName];
    }

    dataToDownload.push(currRowObj);
  }

  return dataToDownload;
};

export const updateClusterMonthlyInputsSubmitHandler = async (
  estimateId,
  clusterId,
  values,
  updateFunc
) => {
  const payload = {
    values,
  };

  const { error } = await updateFunc({
    estimateId: estimateId,
    clusterId: clusterId,
    payload,
  });

  if (error) {
    toastError(error);
  }
};

export const clusterCalculationsDataInputTransformationFunc = (originalData) => {
  // First, call the general Monthly Input transformation func
  const toRet = clusterMonthlyDataInputTransformationFunc(originalData);
  for (const monthData of Object.values(toRet.months)) {
    monthData.storage_minus_replication_gbs = Number(
      monthData.storage_minus_replication_gbs
    ).toLocaleString();
    monthData.storage_including_replication_gbs = Number(
      monthData.storage_including_replication_gbs
    ).toLocaleString();
  }
  return toRet;
};

export const clusterMonthlyDataInputTransformationFunc = (originalData) => {
  const monthlies = originalData.inputs;
  const monthlyInputsTransformed = {};
  for (let monthData of monthlies) {
    monthData = {
      ...monthData,
      name: getMonthName(monthData.month),
    };
    monthlyInputsTransformed[getMonthName(monthData.month)] = monthData;
  }
  return { months: monthlyInputsTransformed };
};

export const connectorsMonthlyDataInputTransformationFunc = (originalData) => {
  const monthlyInputsTransformed = {};
  const connectorsTransformedDataArray = [];
  const dataToUse = originalData.connectors ? originalData.connectors : [];

  for (const connector of dataToUse) {
    connectorsTransformedDataArray.push({
      connectorName: connector.name,
      connectorNetworkingType: connector.input_details.networking_type,
      connectorId: connector.connector_id,
      connectorLabel: connector.label,
      months: clusterMonthlyDataInputTransformationFunc(connector).months,
    });
  }
  monthlyInputsTransformed.connectors = connectorsTransformedDataArray;
  return monthlyInputsTransformed;
};

export const connectorsMonthlyInputsValidationFunc = ({ estimate, cluster }) => {
  const monthsSchema = getMonthlyInputsValidationFuncForType(
    CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG
  )({
    estimate,
  });

  const connectorsList = cluster?.cluster_configs?.full_connectors_list.connector_types;

  const connectorSchemaObject = monthsSchema.concat(
    object({
      connectorName: string()
        .label('Connector Name')
        .required()
        .test(
          'connectorNotFound',
          'This connector is not valid for the chosen configuration of cluster! Please delete!',
          (value, { createError, path, from }) => {
            const formValues = from[2].value;
            const validOptions = connectorsList.filter(
              (x) =>
                x.cluster_type === formValues.cluster_type &&
                x.networking_type === formValues.networking_type &&
                x.provider === formValues.provider &&
                x.connector_name === value
            );

            const isError = validOptions.length === 0;
            if (isError) {
              return createError({
                message: `This connector is not valid for the chosen configuration of cluster! Please delete!`,
                path: path,
              });
            }
            return true;
          }
        ),
      connectorLabel: string().required().label('Connector Label').nullable(),
      connectorNetworkingType: string()
        .required('Connector Networking Type is a required, please add!')
        .label('Connector Networking Type')
        .test(
          'connectorNetworkingTypeNotFound',
          'This Networking Type is not valid for the chosen configuration of cluster! Please delete!',
          (value, { createError, path, from }) => {
            const formValues = from[2].value;
            const currentLevelValue = from[0]?.value;
            const validOptions = connectorsList.filter(
              (x) =>
                x.cluster_type === formValues.cluster_type &&
                x.connector_name === currentLevelValue?.connectorName &&
                x.provider === formValues.provider &&
                x.networking_type === value
            );
            const isError = validOptions.length === 0;
            if (isError) {
              return createError({
                message: `The Networking Type chosen is not valid for the chosen configuration of cluster! Please delete this cluster!`,
                path: path,
              });
            }
            return true;
          }
        ),
    })
  );

  return object({
    connectors: array(connectorSchemaObject).notRequired().default([]),
  });
};

export const getKafkaNewlyDisabledMonths = (kafkaMonthsData, initialValues, values) => {
  const disabledMonths = [];

  for (const [monthName] of Object.entries(kafkaMonthsData)) {
    const kafkaMonthFieldName = `${CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG}[months][${monthName}].is_enabled`;
    const originalEnabledForMonth = getIn(initialValues, kafkaMonthFieldName);
    const currentEnabledForMonth = getIn(values, kafkaMonthFieldName);
    if (originalEnabledForMonth !== currentEnabledForMonth && currentEnabledForMonth === false) {
      disabledMonths.push(monthName);
    }
  }

  return disabledMonths;
};

export const getConnectorInputsFromConnectorValue = (connectorValues) => ({
  connector_id:
    connectorValues.connectorId === DEFAULT_VALUE_FOR_DB_ROW_ID_STR
      ? undefined
      : connectorValues.connectorId,
  name: connectorValues.connectorName,
  input_details: {
    networking_type: connectorValues.connectorNetworkingType,
  },
  label: connectorValues.connectorLabel,
  inputs: Object.values(connectorValues.months),
});

export const getDataForOtherTabsThatNeedToBeSentToBackEnd = (values, disabledMonths) => {
  const ksqlDBValues = cloneDeep(getIn(values, CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG));
  const clusterLinkingValues = cloneDeep(
    getIn(values, CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG)
  );
  const connectorValues = cloneDeep(
    getIn(values, CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG).connectors
  );

  for (const month of disabledMonths) {
    ksqlDBValues.months[month].is_enabled = false;

    if (clusterLinkingValues !== undefined) {
      clusterLinkingValues.months[month].is_enabled = false;
    }
    for (const connector of connectorValues) {
      connector.months[month].is_enabled = false;
    }
  }

  const toRet = {};

  toRet[CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG] = { inputs: Object.values(ksqlDBValues.months) };

  if (clusterLinkingValues !== undefined) {
    toRet[CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG] = {
      inputs: Object.values(clusterLinkingValues.months),
    };
  }

  toRet[CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG] = connectorValues.map((connectorElem) =>
    getConnectorInputsFromConnectorValue(connectorElem)
  );

  return toRet;
};
