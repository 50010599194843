// @flow
import { number, object } from 'yup';
import { getMonthName } from '@components/cluster/cluster-input-table/utils';

const getSPClusterLinkingValidationSchema = (maxLimit) =>
  object({
    avg_throughput_mbps: number()
      .integer()
      .min(0)
      .max(maxLimit)
      .label('This field')
      .typeError('Required Field'),
  });

export const getSPClusterLinkingMonthlyInputsValidation = (numberOfMonths, maxLimitsObject) => {
  const schemaObject = {};
  for (let i = 1; i <= numberOfMonths; i++) {
    schemaObject[getMonthName(i)] = getSPClusterLinkingValidationSchema(
      maxLimitsObject.avg_throughput_mbps
    );
  }
  return object({ months: object(schemaObject) });
};
