// @flow

import {
  CLUSTER_CLUSTER_LINKING_FORM,
  CLUSTER_CONNECTORS_FORM,
  CLUSTER_DETAILS_FORM,
  CLUSTER_KAFKA_FORM,
  CLUSTER_KSQLDB_FORM,
  CLUSTER_TCO_INPUT_FORM,
  ESTIMATE_ACCOUNT_DETAILS_FORM,
  ESTIMATE_CNBC_DETAILS_FORM,
  ESTIMATE_COMMIT_DETAILS_FORM,
  ESTIMATE_CUSTOM_DISCOUNTS_DETAILS_FORM,
  ESTIMATE_TCO_DETAILS_FORM,
  HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
} from '../../constants';
import { getMonthName, getYearName } from '../cluster/cluster-input-table/utils';
import { isFormEditedBasedOnCurrentValues } from '../../common-utils/utils';
import { ESTIMATE_ACCOUNT_DETAILS_CONFIG } from '../../configuration/estimate-account-details';
import { ESTIMATE_COMMIT_DETAILS_CONFIG } from '../../configuration/estimate-commit-details';
import { CUSTOM_DISCOUNTS_JSON_CONFIG } from '../../configuration/custom-discounts';
import { CREDITS_AND_BURSTS_JSON_CONFIG } from '../../configuration/credits-and-bursts';
import { TCO_INPUTS_JSON_CONFIG } from '../../configuration/tco-estimate-inputs';
import { CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP } from '../../configuration/cluster-details';

import {
  CurrencyZeroDPRenderer,
  LinkRendererForMonthlySummaryView,
  MarginRenderer,
} from './grid-utils/renderers';

export const getYearTotalColName = (yearName) => `${yearName} Total`;

const getYearAndMonthColsDef = (yearWiseData, valuesAreCurrencies) => {
  const yearHeaders = [];
  for (const yearDataElem of yearWiseData) {
    const monthlies = [];
    for (const month of yearDataElem.months) {
      monthlies.push({
        headerName: getMonthName(month.month),
        headerTooltip: getMonthName(month.month),
        field: getMonthName(month.month),
        columnGroupShow: 'open',
        minWidth: 100,
        cellRenderer: valuesAreCurrencies ? CurrencyZeroDPRenderer : null,
        headerSuffix: valuesAreCurrencies ? HEADER_SUFFIX_FOR_DOLLAR_COLUMNS : '',
      });
    }

    const yearName = getYearName(yearDataElem.year);

    const yearTotalColName = getYearTotalColName(yearName);
    yearHeaders.push({
      headerName: yearName,
      headerTooltip: yearName,
      children: [
        {
          headerName: yearTotalColName,
          headerTooltip: yearTotalColName,
          field: yearTotalColName,
          columnGroupShow: 'closed',
          cellRenderer: valuesAreCurrencies ? CurrencyZeroDPRenderer : null,
          headerSuffix: valuesAreCurrencies ? HEADER_SUFFIX_FOR_DOLLAR_COLUMNS : '',
        },
        ...monthlies,
      ],
    });
  }
  return yearHeaders;
};

export const getColumnDefsForMonthlySummaryBasedOnGivenData = (
  data,
  valuesAreCurrencies = true,
  addCostMargin = false
) => {
  const yearAndMonthColsDef = getYearAndMonthColsDef(
    data?.[0]?.yearlies ?? [],
    valuesAreCurrencies
  );

  return [
    {
      headerName: 'Summary',
      children: [
        {
          headerName: 'Field',
          headerTooltip: 'Field',
          field: 'Field',
          cellRenderer: LinkRendererForMonthlySummaryView,
        },
        {
          headerName: 'Total',
          headerTooltip: 'Total',
          field: 'Total',
          cellRenderer: valuesAreCurrencies ? CurrencyZeroDPRenderer : null,
          headerSuffix: valuesAreCurrencies ? HEADER_SUFFIX_FOR_DOLLAR_COLUMNS : '',
        },
        ...(addCostMargin
          ? [
              {
                headerName: 'Cost',
                headerTooltip: 'Cost',
                field: 'Cost',
                cellRenderer: valuesAreCurrencies ? CurrencyZeroDPRenderer : null,
                headerSuffix: valuesAreCurrencies ? HEADER_SUFFIX_FOR_DOLLAR_COLUMNS : '',
              },
              {
                headerName: 'Margin',
                headerTooltip: 'Margin',
                field: 'Margin',
                cellRenderer: MarginRenderer,
                headerSuffix: valuesAreCurrencies ? HEADER_SUFFIX_FOR_DOLLAR_COLUMNS : '',
              },
            ]
          : []),
      ],
    },
    ...yearAndMonthColsDef,
  ];
};

export const constructDataForMonthlySummaryUI = (
  data,
  valueColumnToFetchDataFromForMonths = 'spend_discounted',
  addCostMargin = false
) => {
  const tableData = [];

  for (const row of data) {
    const currRowObj = {};
    currRowObj.Field = row.field;
    currRowObj.Total = row.total_discounted;
    currRowObj.link = row.link;
    if (addCostMargin) {
      currRowObj.Cost = row.cost;
      currRowObj.Margin = row.margin;
    }
    for (const yearDataElem of row.yearlies) {
      const yearName = getYearName(yearDataElem.year);
      currRowObj[getYearTotalColName(yearName)] = yearDataElem.total_discounted;
      for (const month of yearDataElem.months) {
        currRowObj[getMonthName(month.month)] = month[valueColumnToFetchDataFromForMonths];
      }
    }
    tableData.push(currRowObj);
  }

  return tableData;
};

export const isAnythingEditedOnEstimatePage = (values, initialValues) => {
  return (
    isFormEditedBasedOnCurrentValues(values, initialValues, ESTIMATE_ACCOUNT_DETAILS_CONFIG) ||
    isFormEditedBasedOnCurrentValues(values, initialValues, ESTIMATE_COMMIT_DETAILS_CONFIG) ||
    isFormEditedBasedOnCurrentValues(values, initialValues, [TCO_INPUTS_JSON_CONFIG]) ||
    isFormEditedBasedOnCurrentValues(values, initialValues, [CUSTOM_DISCOUNTS_JSON_CONFIG]) ||
    isFormEditedBasedOnCurrentValues(values, initialValues, [CREDITS_AND_BURSTS_JSON_CONFIG])
  );
};

const getIfAnyOtherFormIsEditedBasedOnTheGivenMap = (
  clusterFormNamesToFieldsArrayMap,
  currentFormName,
  values,
  initialValues
) => {
  for (const [formName, formFields] of Object.entries(clusterFormNamesToFieldsArrayMap)) {
    if (formName === currentFormName) {
      continue;
    }
    if (isFormEditedBasedOnCurrentValues(values, initialValues, formFields)) {
      return true;
    }
  }

  return false;
};

export const isAnythingEditedOnClusterPageBesidesTheGivenForm = (
  values,
  initialValues,
  currentFormName
) => {
  const clusterFormNamesToFieldsArrayMap = {
    [CLUSTER_DETAILS_FORM]: CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP.get(CLUSTER_DETAILS_FORM),
    [CLUSTER_KAFKA_FORM]: CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP.get(CLUSTER_KAFKA_FORM),
    [CLUSTER_KSQLDB_FORM]: CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP.get(CLUSTER_KSQLDB_FORM),
    [CLUSTER_CONNECTORS_FORM]:
      CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP.get(CLUSTER_CONNECTORS_FORM),
    [CLUSTER_CLUSTER_LINKING_FORM]: CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP.get(
      CLUSTER_CLUSTER_LINKING_FORM
    ),
    [CLUSTER_TCO_INPUT_FORM]: CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP.get(CLUSTER_TCO_INPUT_FORM),
  };

  return getIfAnyOtherFormIsEditedBasedOnTheGivenMap(
    clusterFormNamesToFieldsArrayMap,
    currentFormName,
    values,
    initialValues
  );
};

export const isAnythingEditedOnEstimatePageBesidesTheGivenForm = (
  values,
  initialValues,
  currentFormName
) => {
  const estimateFormNamesToFieldsArrayMap = {
    [ESTIMATE_ACCOUNT_DETAILS_FORM]: ESTIMATE_ACCOUNT_DETAILS_CONFIG,
    [ESTIMATE_COMMIT_DETAILS_FORM]: ESTIMATE_COMMIT_DETAILS_CONFIG,
    [ESTIMATE_TCO_DETAILS_FORM]: [TCO_INPUTS_JSON_CONFIG],
    [ESTIMATE_CUSTOM_DISCOUNTS_DETAILS_FORM]: [CUSTOM_DISCOUNTS_JSON_CONFIG],
    [ESTIMATE_CNBC_DETAILS_FORM]: [CREDITS_AND_BURSTS_JSON_CONFIG],
  };

  return getIfAnyOtherFormIsEditedBasedOnTheGivenMap(
    estimateFormNamesToFieldsArrayMap,
    currentFormName,
    values,
    initialValues
  );
};

const extractDataFromASingleRowRecursivelyAndAddToTheTableDataOutput = (
  row,
  tableData,
  columnNamesSourceOfTruth,
  depth = -1, // We are starting at -1 because the initial level is used for the row header
  rowHeaderTrail = []
) => {
  // First, don't worry about the children and just take the parents data
  let formattedRow = {};
  formattedRow.depth = depth;
  formattedRow.rowHeaderTrail = [...rowHeaderTrail];
  const rowHeader = row.row_header;
  formattedRow.field = rowHeader;
  const columns = row?.columns ?? [];

  for (let i = 0; i <= columns.length - 1; i++) {
    formattedRow[columnNamesSourceOfTruth[i]] = columns[i].column_value;
  }

  // This represents a top level row
  if (rowHeader != null && depth === -1) {
    formattedRow = {
      ...formattedRow,
      field: rowHeader,
      depth: 0,
      isAHeaderLevelRow: true,
      rowHeaderTrail: [],
    };
  }

  tableData.push(formattedRow);

  // Now, check if children are available and gather them too
  const children = row?.children ?? [];

  if (children) {
    children.forEach((childRow) => {
      extractDataFromASingleRowRecursivelyAndAddToTheTableDataOutput(
        childRow,
        tableData,
        columnNamesSourceOfTruth,
        depth + 1,
        [...rowHeaderTrail, rowHeader]
      );
    });
  }
};

export const constructTableDataForViewBasedOnTheNewRecursiveFormat = (
  inputDataSource,
  columnNamesSourceOfTruth
) => {
  const tableData = [];

  inputDataSource.forEach((row) => {
    extractDataFromASingleRowRecursivelyAndAddToTheTableDataOutput(
      row,
      tableData,
      columnNamesSourceOfTruth
    );
  });

  return tableData;
};
