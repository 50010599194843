// @flow
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { STREAMING_PROJECTS_API } from '@streaming-projects/constants';

const getInvalidatesTagsFunc = (arrayOfTagsToInvalidate) => (result, error) => {
  // Don't invalidate the cache if a update conflict occurs (HTTP 409).
  // This prevents the latest `version` being fetched via a requery and
  // stops further updates until the browser is refreshed by the user
  if (error?.status === 409) {
    return [];
  }
  return arrayOfTagsToInvalidate;
};

export const streamingProjectsApi = createApi({
  reducerPath: STREAMING_PROJECTS_API,
  baseQuery: fetchBaseQuery({
    baseUrl: `${window?._env_?.API_URL}/api/v2/`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
      headers.set('Authorization', token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  tagTypes: ['StreamingProjects'],
  endpoints: (builder) => {
    return {
      // **************** Streaming Projects Related ***************** ///

      getStreamingProjectsHierarchy: builder.query({
        query: ({ query, limit }) => {
          return {
            url: 'views/orghierarchy',
            params: { query, limit },
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      // Add Org
      addOrganization: builder.mutation({
        query: (body) => {
          return {
            url: '/orgs',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Get SP Org
      getSPOrgLevelView: builder.query({
        query: ({ orgId }) => {
          return {
            url: `views/orgs/${orgId}?query=HOME`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      updateSPOrg: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Streaming Projects Related

      getSPStreamingProjectLevelView: builder.query({
        query: ({ orgId, spId }) => {
          return {
            url: `views/orgs/${orgId}/streaming-projects/${spId}?query=HOME`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      getSPStreamingProjectPricingStatusView: builder.query({
        query: ({ orgId, spId }) => {
          return {
            url: `views/orgs/${orgId}/streaming-projects/${spId}/pricing-status`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      getSPStreamingProjectMonthlyPricingView: builder.query({
        query: ({ orgId, spId }) => {
          return {
            url: `views/orgs/${orgId}/streaming-projects/${spId}/cloud-monthly-spend`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      deleteStreamingProject: builder.mutation({
        query: ({ orgId, spId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      addStreamingProject: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      submitRecalcRequestForStreamingProject: builder.mutation({
        query: ({ orgId, spId, payload }) => {
          return {
            url: `views/orgs/${orgId}/streaming-projects/${spId}/calculate`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateStreamingProject: builder.mutation({
        query: ({ orgId, spId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Resources Related
      addFlinkPoolResource: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/flink-pools`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateFlinkPoolResource: builder.mutation({
        query: ({ orgId, flinkPoolResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/flink-pools/${flinkPoolResourceId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteFlinkPoolResource: builder.mutation({
        query: ({ orgId, flinkPoolResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/flink-pools/${flinkPoolResourceId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      getStreamingProjectsMetadata: builder.query({
        query: ({ query }) => {
          return {
            url: 'views/metadata/summary',
            params: { query },
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      addClusterResource: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-resources`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateClusterResource: builder.mutation({
        query: ({ orgId, clusterResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-resources/${clusterResourceId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteClusterResource: builder.mutation({
        query: ({ orgId, clusterResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-resources/${clusterResourceId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      addClusterLinkingResource: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-linking-resources`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateClusterLinkingResource: builder.mutation({
        query: ({ orgId, clusterLinkingResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-linking-resources/${clusterLinkingResourceId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteClusterLinkingResource: builder.mutation({
        query: ({ orgId, clusterLinkingResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/cluster-linking-resources/${clusterLinkingResourceId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      addStreamGovernanceResource: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/stream-governance-resources`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateStreamGovernanceResource: builder.mutation({
        query: ({ orgId, streamGovernanceResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/stream-governance-resources/${streamGovernanceResourceId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteStreamGovernanceResource: builder.mutation({
        query: ({ orgId, streamGovernanceResourceId, payload }) => {
          return {
            url: `orgs/${orgId}/stream-governance-resources/${streamGovernanceResourceId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Flink Workloads Related
      getSPFlinkWorkloadView: builder.query({
        query: ({ orgId, spId, flinkWorkloadId }) => {
          return {
            url: `views/orgs/${orgId}/streaming-projects/${spId}/flink-pools/${flinkWorkloadId}/summary?query=HOME`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      updateFlinkWorkload: builder.mutation({
        query: ({ orgId, spId, flinkWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/flink-pools/${flinkWorkloadId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      addFlinkWorkload: builder.mutation({
        query: ({ orgId, spId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/flink-pools`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteFlinkWorkload: builder.mutation({
        query: ({ orgId, spId, flinkWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/flink-pools/${flinkWorkloadId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      enableFlinkWorkload: builder.mutation({
        query: ({ orgId, spId, flinkWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/flink-pools/${flinkWorkloadId}/enable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      disableFlinkWorkload: builder.mutation({
        query: ({ orgId, spId, flinkWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/flink-pools/${flinkWorkloadId}/disable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Cluster Linking Workloads Related
      getSPClusterLinkingWorkloadView: builder.query({
        query: ({ orgId, spId, clusterLinkingWorkloadId }) => {
          return {
            url: `views/orgs/${orgId}/streaming-projects/${spId}/cluster-linking-details/${clusterLinkingWorkloadId}/summary?query=HOME`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      updateClusterLinkingWorkload: builder.mutation({
        query: ({ orgId, spId, clusterLinkingWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-linking-details/${clusterLinkingWorkloadId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      addClusterLinkingWorkload: builder.mutation({
        query: ({ orgId, spId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-linking-details`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteClusterLinkingWorkload: builder.mutation({
        query: ({ orgId, spId, clusterLinkingWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-linking-details/${clusterLinkingWorkloadId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      enableClusterLinkingWorkload: builder.mutation({
        query: ({ orgId, spId, clusterLinkingWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-linking-details/${clusterLinkingWorkloadId}/enable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      disableClusterLinkingWorkload: builder.mutation({
        query: ({ orgId, spId, clusterLinkingWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-linking-details/${clusterLinkingWorkloadId}/disable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Stream Governance Workloads

      getSPStreamGovernanceWorkloadView: builder.query({
        query: ({ orgId, spId, streamGovernanceWorkloadId }) => {
          return {
            url: `views/orgs/${orgId}/streaming-projects/${spId}/stream-governance-details/${streamGovernanceWorkloadId}/summary?query=HOME`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      addStreamGovernanceWorkload: builder.mutation({
        query: ({ orgId, spId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/stream-governance-details`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      enableStreamGovernanceWorkload: builder.mutation({
        query: ({ orgId, spId, streamGovernanceWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/stream-governance-details/${streamGovernanceWorkloadId}/enable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      disableStreamGovernanceWorkload: builder.mutation({
        query: ({ orgId, spId, streamGovernanceWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/stream-governance-details/${streamGovernanceWorkloadId}/disable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteStreamGovernanceWorkload: builder.mutation({
        query: ({ orgId, spId, streamGovernanceWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/stream-governance-details/${streamGovernanceWorkloadId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateStreamGovernanceWorkload: builder.mutation({
        query: ({ orgId, spId, streamGovernanceWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/stream-governance-details/${streamGovernanceWorkloadId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // cluster Workloads

      getSPClusterWorkloadView: builder.query({
        query: ({ orgId, spId, clusterWorkloadId }) => {
          return {
            url: `views/orgs/${orgId}/streaming-projects/${spId}/cluster-details/${clusterWorkloadId}/summary?query=HOME`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      addClusterWorkload: builder.mutation({
        query: ({ orgId, spId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-details`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteClusterWorkload: builder.mutation({
        query: ({ orgId, spId, clusterWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-details/${clusterWorkloadId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      enableClusterWorkload: builder.mutation({
        query: ({ orgId, spId, clusterWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-details/${clusterWorkloadId}/enable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      disableClusterWorkload: builder.mutation({
        query: ({ orgId, spId, clusterWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-details/${clusterWorkloadId}/disable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateClusterWorkload: builder.mutation({
        query: ({ orgId, spId, clusterWorkloadId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-details/${clusterWorkloadId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),
      deleteSPConnector: builder.mutation({
        query: ({ orgId, spId, clusterWorkloadId, connectorId, payload }) => {
          return {
            url: `orgs/${orgId}/streaming-projects/${spId}/cluster-details/${clusterWorkloadId}/connectors/${connectorId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),
      // discounts API mutations

      updateGlobalDiscount: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/discounts`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      createMetricDiscount: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/custom-discounts/metric-discounts`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      updateMetricDiscount: builder.mutation({
        query: ({ orgId, payload, metricsDiscountId }) => {
          return {
            url: `orgs/${orgId}/custom-discounts/metric-discounts/${metricsDiscountId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteMetricDiscount: builder.mutation({
        query: ({ orgId, payload, metricDiscountId }) => {
          return {
            url: `orgs/${orgId}/custom-discounts/metric-discounts/${metricDiscountId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      deleteMetricDiscountGroup: builder.mutation({
        query: ({ orgId, payload }) => {
          return {
            url: `orgs/${orgId}/custom-discounts/metric-discounts`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Commit Details Queries

      // CTODO:SP: Revisit after API finalization
      getSPCommitDetails: builder.query({
        // query: ({ orgId, commitId }) => {
        //   return {
        //     url: `views/orgs/${orgId}/commits/${commitId}?query=HOME`,
        //   };
        // },

        // CTODO:SP: Remove this mock data
        queryFn: () =>
          new Promise((resolve) =>
            resolve({
              data: {
                commit_name: 'default',
                commit_id: 'CMT-fdee6478-a194-4d90-b9c9-06cdeda5bd9b',
                start_date: '2023-01-30T00:00:00Z',
                end_date: '2023-01-30T00:00:00Z',
                total_spends: 500,
                total_spends_discounted: 450,
              },
            })
          ),

        providesTags: ['StreamingProjects'],
      }),

      // CTODO:SP: Revisit after API finalization
      getSPCommitMonthlyPricingView: builder.query({
        // query: ({ orgId, commitId }) => {
        //   return {
        //     url: `api/v2/views/orgs/${orgId}/commits/${commitId}?query=COMMIT_CLOUD_MONTHLY_SPEND`,
        //   };
        // },

        // CTODO:SP: Remove this mock data
        queryFn: () =>
          new Promise((resolve) =>
            resolve({
              data: {
                commit_cloud_monthly_spend: [
                  {
                    field: 'Total',
                    field_type: 'total',
                    total: 6250.729927007299,
                    total_discounted: 6250.729927007299,
                    commit_name: 'default',
                    commit_id: 'CMT-fdee6478-a194-4d90-b9c9-06cdeda5bd9b',
                    start_date: '2023-01-30T00:00:00Z',
                    end_date: '2023-01-30T00:00:00Z',
                    yearlies: [
                      {
                        year: 1,
                        calendar_year: '2025',
                        total: 6250.729927007299,
                        total_discounted: 6250.729927007299,
                        months: [
                          {
                            month: 1,
                            calendar_month: 'Jan-2025',
                            spend: 6250.729927007299,
                            spend_discounted: 6250.729927007299,
                          },
                        ],
                      },
                    ],
                    link: '',
                  },
                ],
              },
            })
          ),
        providesTags: ['StreamingProjects'],
      }),

      // CTODO::SP Revisit after API finalization
      getSPCommitPricingStatusView: builder.query({
        // query: ({ orgId }) => {
        //   return {
        //     url: `GET /views/org/${orgId}/defaultcommit/calcstatus`,
        //   };
        // },

        // CTODO::SP Remove this mock data
        queryFn: () =>
          new Promise((resolve) =>
            resolve({
              data: {
                org_id: 'org_id',
                commit_id: 'commit_id',
                status: 'success',
              },
            })
          ),

        providesTags: ['StreamingProjects'],
      }),

      // CTODO::SP Revisit after API finalization
      submitRecalcRequestForCommit: builder.mutation({
        // CTODO::SP check if payload is required
        // query: ({ orgId, payload }) => {
        //   return {
        //     url: `/views/org/${orgId}/defaultcommit/calculate`,
        //     method: 'PUT',
        //     body: payload,
        //   };
        // },
        // CTODO::SP Remove this mock data
        queryFn: () =>
          new Promise((resolve) =>
            resolve({
              data: {
                org_id: 'org_id',
                commit_id: 'commit_id',
                status: 'success',
                error_reason: [],
              },
            })
          ),
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Commit Details Mutations
      updateSPCommitDetails: builder.mutation({
        query: ({ orgId, commitId, payload }) => {
          return {
            url: `api/v2/orgs/${orgId}/commit/${commitId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // End of Config
    };
  },
});

export const {
  // Hierarchy Related
  useGetStreamingProjectsHierarchyQuery,

  // Orgs Related
  useAddOrganizationMutation,
  useGetSPOrgLevelViewQuery,
  useUpdateSPOrgMutation,

  // Streaming Projects Related
  useAddStreamingProjectMutation,
  useUpdateStreamingProjectMutation,
  useDeleteStreamingProjectMutation,
  useGetSPStreamingProjectLevelViewQuery,
  useSubmitRecalcRequestForStreamingProjectMutation,
  useGetSPStreamingProjectPricingStatusViewQuery,
  useGetSPStreamingProjectMonthlyPricingViewQuery,

  // Metadata Related
  useGetStreamingProjectsMetadataQuery,
  useLazyGetStreamingProjectsMetadataQuery,

  // Resources Related

  // Flink Resources
  useAddFlinkPoolResourceMutation,
  useUpdateFlinkPoolResourceMutation,
  useDeleteFlinkPoolResourceMutation,

  // Cluster Resources
  useAddClusterResourceMutation,
  useUpdateClusterResourceMutation,
  useDeleteClusterResourceMutation,

  // Cluster Linking Resources
  useAddClusterLinkingResourceMutation,
  useUpdateClusterLinkingResourceMutation,
  useDeleteClusterLinkingResourceMutation,

  // Stream Governance Related
  useAddStreamGovernanceResourceMutation,
  useUpdateStreamGovernanceResourceMutation,
  useDeleteStreamGovernanceResourceMutation,

  // Workload Related

  // Cluster Workloads
  useGetSPClusterWorkloadViewQuery,
  useAddClusterWorkloadMutation,
  useDeleteClusterWorkloadMutation,
  useEnableClusterWorkloadMutation,
  useDisableClusterWorkloadMutation,
  useUpdateClusterWorkloadMutation,

  // Connectors
  useDeleteSPConnectorMutation,

  // Flink Workloads
  useGetSPFlinkWorkloadViewQuery,
  useAddFlinkWorkloadMutation,
  useUpdateFlinkWorkloadMutation,
  useDeleteFlinkWorkloadMutation,
  useEnableFlinkWorkloadMutation,
  useDisableFlinkWorkloadMutation,

  // Cluster Linking Workloads
  useGetSPClusterLinkingWorkloadViewQuery,
  useAddClusterLinkingWorkloadMutation,
  useUpdateClusterLinkingWorkloadMutation,
  useDeleteClusterLinkingWorkloadMutation,
  useEnableClusterLinkingWorkloadMutation,
  useDisableClusterLinkingWorkloadMutation,

  // Stream Governance Workloads
  useGetSPStreamGovernanceWorkloadViewQuery,
  useAddStreamGovernanceWorkloadMutation,
  useUpdateStreamGovernanceWorkloadMutation,
  useEnableStreamGovernanceWorkloadMutation,
  useDisableStreamGovernanceWorkloadMutation,
  useDeleteStreamGovernanceWorkloadMutation,

  // Discounts Related
  useUpdateGlobalDiscountMutation,
  useCreateMetricDiscountMutation,
  useUpdateMetricDiscountMutation,
  useDeleteMetricDiscountGroupMutation,
  useDeleteMetricDiscountMutation,

  // Commit Details Queries
  useGetSPCommitDetailsQuery,
  useGetSPCommitMonthlyPricingViewQuery,
  useGetSPCommitPricingStatusViewQuery,

  // Commit Details Mutations
  useUpdateSPCommitDetailsMutation,
  useSubmitRecalcRequestForCommitMutation,
} = streamingProjectsApi;
