// @flow
import SPPricingSummaryWrapper from '@components/common/pricing-summary-wrapper/SPPricingSummaryWrapper';
import {
  useGetSPCommitPricingStatusViewQuery,
  useSubmitRecalcRequestForCommitMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import React, { useCallback } from 'react';

import SPCommitPricingMonthlySpendSummaryContainerWrapper from './SPCommitPricingMonthlySpendSummaryContainerWrapper';

const SPCommitPricingSummaryContainer = () => {
  const pricingStatusFetchingFunction = useCallback(useGetSPCommitPricingStatusViewQuery, []);

  return (
    <SPPricingSummaryWrapper
      pricingStatusFetchingFunction={pricingStatusFetchingFunction}
      useRecalcRequestMutation={useSubmitRecalcRequestForCommitMutation}
    >
      <SPCommitPricingMonthlySpendSummaryContainerWrapper addCostMargin={false} disabled={false} />
    </SPPricingSummaryWrapper>
  );
};

export default SPCommitPricingSummaryContainer;
