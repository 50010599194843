// @flow

import React from 'react';
import SPCommitSummary from '@streaming-projects/orgs/org-top-level/sp-commit-summary/index';
import SPResourceConfigurations from '@streaming-projects/resource-definitions/SPResourceConfigurationsTopLevelContainer';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { SPOrganizationDetailsContainer } from '@streaming-projects/orgs/org-top-level/SPOrganizationDetailsContainer';
import { StreamingProjectsSummaryContainer } from '@streaming-projects/orgs/org-top-level/sp-summary/StreamingProjectsSummaryContainer';
import { SPDiscountsTopLevelContainer } from '@streaming-projects/orgs/org-top-level/sp-custom-discounts/SPDiscountsTopLevelContainer';
import { BaselineSummaryContainer } from '@streaming-projects/baseline/BaselineSummaryContainer';
import { IS_DEFAULT_COMMIT_SECTION_LIVE } from '@streaming-projects/constants';

export const SPOrgAccordionsContainer = () => {
  const accordionPanels = [
    getAccordianPanelDetailsObject('Org Details', <SPOrganizationDetailsContainer />),
    getAccordianPanelDetailsObject('Discounts', <SPDiscountsTopLevelContainer />),
    getAccordianPanelDetailsObject('Baseline', <BaselineSummaryContainer />),
    getAccordianPanelDetailsObject(
      'Streaming Projects Configurations',
      <StreamingProjectsSummaryContainer />
    ),
    getAccordianPanelDetailsObject('Resource Configurations', <SPResourceConfigurations />),
    ...(IS_DEFAULT_COMMIT_SECTION_LIVE
      ? [getAccordianPanelDetailsObject('Commit Details', <SPCommitSummary />)]
      : []),
  ];

  return (
    <>
      <AccordionsList panels={accordionPanels} />
    </>
  );
};
