// @flow
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import React from 'react';

import SPCommitGenericDetails from './commit-generic-details/SPCommitGenericDetails';
import SPCommitValueDetails from './commit-value/SPCommitValueDetails';
import SPCommitPricingSummaryContainer from './commit-pricing-summary/SPCommitPricingSummaryContainer';

/**
 * Details page of a commit which is shown when the user clicks on a commit in the Org Commit Summary grid
 */
const SPCommitDetailsAccordions = () => {
  const accordionPanels = [
    getAccordianPanelDetailsObject('Commit Details', <SPCommitGenericDetails />),
    getAccordianPanelDetailsObject('Enter Commit Value', <SPCommitValueDetails />),
    getAccordianPanelDetailsObject('Commit Pricing Summary', <SPCommitPricingSummaryContainer />),
  ];

  return (
    <AccordionsList
      initialIndexes={[0]}
      panels={accordionPanels}
      showOnlyTitleInCollapsedState={true}
    />
  );
};

export default SPCommitDetailsAccordions;
