// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { Grid } from 'semantic-ui-react';
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { useUpdateSPCommitDetailsMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { Spacer } from '@presentational/spacing/Spacer';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { useSPCommitDetailsContext } from '@streaming-projects/commit-details/context/SPCommitDetailsContext';
import { SP_COMMIT_DETAILS_CONFIG } from '@streaming-projects/commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

import SPCommitNameContainer from './fields/SPCommitNameContainer';
import SPCommitDealStartDateContainer from './fields/SPCommitDealStartDateContainer';
import SPCommitDealEndDateContainer from './fields/SPCommitDealEndDateContainer';
import SPCommitSupportTierContainer from './fields/SPCommitSupportTierContainer';

const SPCommitGenericDetails = () => {
  const spOrgContext = useContext(SPOrgContext);
  const spCommitDetailsContext = useSPCommitDetailsContext();
  const [updateSPCommitDetails] = useUpdateSPCommitDetailsMutation();

  const handleSave = async (payload) => {
    return await updateSPCommitDetails({
      orgId: spOrgContext.org.id,
      commitId: spCommitDetailsContext.commit_id,
      payload: {
        // TODO::SP Allow other values to be updated, Once they are supported by the API
        support_tier: payload.support_tier,
      },
    });
  };

  return (
    <PaddedAndRaisedSegment>
      <Grid columns={4}>
        <StyledGridRow coloumns={1}>
          <Grid.Column>
            <SPCommitNameContainer />
          </Grid.Column>
        </StyledGridRow>

        <StyledGridRow coloumns={3}>
          <Grid.Column>
            <SPCommitDealStartDateContainer />
          </Grid.Column>

          <Grid.Column>
            <SPCommitDealEndDateContainer />
          </Grid.Column>

          <Grid.Column>
            <SPCommitSupportTierContainer />
          </Grid.Column>
        </StyledGridRow>
      </Grid>

      <Spacer y={40} />

      <GenericSaveResetButtons
        fieldsConfig={SP_COMMIT_DETAILS_CONFIG.filter(
          (config) => config.backendFieldName !== BACKEND_FIELDNAMES.COMMIT_VALUE
        )}
        onSaveHandler={handleSave}
      />
    </PaddedAndRaisedSegment>
  );
};

export default SPCommitGenericDetails;
