// @flow
import React, { useCallback } from 'react';
import {
  useGetSPStreamingProjectPricingStatusViewQuery,
  useSubmitRecalcRequestForStreamingProjectMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectPricingMonthlySpendSummaryContainerWrapper } from '@streaming-projects/sp-page/accordions-container/SPStreamingProjectPricingMonthlySpendSummaryContainerWrapper';
import SPPricingSummaryWrapper from '@components/common/pricing-summary-wrapper/SPPricingSummaryWrapper';
import { IS_COST_AND_MARGIN_COLUMNS_ENABLED } from '@streaming-projects/constants';
import useIsAdmin from '@streaming-projects/useIsAdmin';

export const SPStreamingProjectPricingSummaryContainer = () => {
  const isUserAdmin = useIsAdmin();

  const pricingStatusFetchingFunction = useCallback(
    useGetSPStreamingProjectPricingStatusViewQuery,
    []
  );

  return (
    <SPPricingSummaryWrapper
      pricingStatusFetchingFunction={pricingStatusFetchingFunction}
      useRecalcRequestMutation={useSubmitRecalcRequestForStreamingProjectMutation}
    >
      <SPStreamingProjectPricingMonthlySpendSummaryContainerWrapper
        addCostMargin={IS_COST_AND_MARGIN_COLUMNS_ENABLED && isUserAdmin}
        disabled={false}
      />
    </SPPricingSummaryWrapper>
  );
};
