// @flow

import { CLUSTER_LINKING_RESOURCES_GRID_HEADERS } from '@streaming-projects/orgs/enums';
import { toastError } from '@presentational/notifications/utils';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';

export const EXTERNAL_CLUSTER_DEFINITION = {
  id: 'CL-RES-00000000-0000-0000-0000-000000000000',
  name: 'External Cluster',
  cluster_type: 'External',
  networking_type: '-',
  provider: '-',
  region: '-',
  availability: '-',
  sla: '-',
};

export const getSourceClustersForClusterLinking = (clusterResourceConfigurations) => {
  const sourceClustersToUse = [...clusterResourceConfigurations];
  sourceClustersToUse.push(EXTERNAL_CLUSTER_DEFINITION);
  return sourceClustersToUse;
};

export const getDestinationClustersForClusterLinking = (clusterResourceConfigurations) => {
  let destinationClustersToUse = [...clusterResourceConfigurations];
  destinationClustersToUse = destinationClustersToUse = destinationClustersToUse.filter(
    (x) => x.cluster_type === 'dedicated'
  );
  destinationClustersToUse.push(EXTERNAL_CLUSTER_DEFINITION);
  return destinationClustersToUse;
};

export const areSourceAndDestinationClustersSame = (
  selectedSourceCluster,
  selectedDestinationCluster
) => {
  if (!selectedSourceCluster || !selectedDestinationCluster) {
    return false;
  }
  return selectedSourceCluster['Cluster ID'] === selectedDestinationCluster['Cluster ID'];
};

export const getIfThereIsAnExistingLinkBetweenChosenSourceAndDest = (
  selectedSourceCluster,
  selectedDestinationCluster,
  existingClusterLinks
) => {
  const chosenSource = selectedSourceCluster?.['Cluster ID'];
  const chosenDestination = selectedDestinationCluster?.['Cluster ID'];

  return (
    existingClusterLinks.filter(
      (x) => x.source_cluster_id === chosenSource && x.destination_cluster_id === chosenDestination
    ).length > 0
  );
};

export const getAddAndUpdateParams = (
  orgId,
  selectedSourceRow,
  selectedDestinationRow,
  clusterLinkName,
  initialValues
) => {
  // Note, clusterLinkingResourceId and Version are only applicable in Editing Mode and hence the usage of "?."
  return {
    orgId,
    clusterLinkingResourceId: initialValues?.[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.ID],
    payload: {
      org_id: orgId,
      name: clusterLinkName,
      source_cluster_resource_id: selectedSourceRow['Cluster ID'],
      destination_cluster_resource_id: selectedDestinationRow['Cluster ID'],
      version: initialValues?.[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.VERSION],
    },
  };
};

export const shouldContinueButtonBeDisabled = (
  selectedSourceRow,
  selectedDestinationRow,
  addClusterLinkingFormik,
  existingClusterLinks,
  isEditingMode,
  initialValuesToUse
) => {
  const areSelectedSourceAndDestinationClustersSame = areSourceAndDestinationClustersSame(
    selectedSourceRow,
    selectedDestinationRow
  );

  const isAClusterLinkAlreadyPresentBetweenTheChosenSourceAndDestination =
    getIfThereIsAnExistingLinkBetweenChosenSourceAndDest(
      selectedSourceRow,
      selectedDestinationRow,
      existingClusterLinks
    );

  if (!isEditingMode) {
    // On Add, follow these rules:
    // 1. If either the source or the destination is not selected, then, disable
    // 2. If they are both selected, but are the same, then, disable
    // 3. If a user is trying to add a CL which is already there, then disable
    // 4. Ensure form is valid and is dirty ( this is mainly for the name )
    return (
      !selectedSourceRow ||
      !selectedDestinationRow ||
      areSelectedSourceAndDestinationClustersSame ||
      isAClusterLinkAlreadyPresentBetweenTheChosenSourceAndDestination ||
      !addClusterLinkingFormik.isValid ||
      !addClusterLinkingFormik.dirty
    );
  }

  // On Edit, follow these rules:
  // 1. If either the source or the destination is not selected, then, disable
  // 2. If they are both selected, but are the same, then, disable
  // 3. If a user is trying to add a CL which is already there, then disable
  // But, if the user has not changed the source or destination, then, this check should not apply
  // 4. Either the name or the source or destination should have been changed

  const isSourceChanged =
    initialValuesToUse.source_cluster_id !== selectedSourceRow?.['Cluster ID'];
  const isDestinationChanged =
    initialValuesToUse.destination_cluster_id !== selectedDestinationRow?.['Cluster ID'];
  const isNameChanged = addClusterLinkingFormik.dirty;

  return (
    !selectedSourceRow ||
    !selectedDestinationRow ||
    areSelectedSourceAndDestinationClustersSame ||
    (isAClusterLinkAlreadyPresentBetweenTheChosenSourceAndDestination &&
      (isSourceChanged || isDestinationChanged)) ||
    (!isSourceChanged && !isDestinationChanged && !isNameChanged)
  );
};

export const handleClusterLinkAddOrEdit = async (
  addClusterLinkingFormik,
  isEditingMode,
  addCLResource,
  orgId,
  selectedSourceRow,
  selectedDestinationRow,
  initialValues,
  updateCLResource,
  push,
  setOpen,
  setSelectedSourceRow,
  setSelectedDestinationRow
) => {
  let errorInCreateOrUpdate;
  const clusterLinkName = addClusterLinkingFormik.values.name;
  if (!isEditingMode) {
    const { error } = await addCLResource(
      getAddAndUpdateParams(
        orgId,
        selectedSourceRow,
        selectedDestinationRow,
        clusterLinkName,
        initialValues
      )
    );
    errorInCreateOrUpdate = error;
  } else {
    const { error } = await updateCLResource(
      getAddAndUpdateParams(
        orgId,
        selectedSourceRow,
        selectedDestinationRow,
        clusterLinkName,
        initialValues
      )
    );
    errorInCreateOrUpdate = error;
  }

  if (errorInCreateOrUpdate) {
    toastError(errorInCreateOrUpdate);
  } else {
    push(getLinkForOrganizationsPage(orgId));
  }

  setOpen(false);
  setSelectedSourceRow(null);
  setSelectedDestinationRow(null);
};
