// @flow
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import React from 'react';
import { Spacer } from '@presentational/spacing/Spacer';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { Grid } from 'semantic-ui-react';
import { SP_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

import SPCommitValueContainer from './fields/SPCommitValueContainer';

const SPCommitValueDetails = () => {
  const handleSave = async () => {
    // CTODO::SP Implement
  };

  return (
    <PaddedAndRaisedSegment>
      <Grid>
        <Grid.Column width={4}>
          <SPCommitValueContainer />
        </Grid.Column>
      </Grid>

      <Spacer y={40} />

      <GenericSaveResetButtons
        fieldsConfig={[SP_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.COMMIT_VALUE)]}
        onSaveHandler={handleSave}
      />
    </PaddedAndRaisedSegment>
  );
};

export default SPCommitValueDetails;
