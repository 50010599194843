// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';

import SPCommitDetailsBreadcrumbs from './SPCommitDetailsBreadcrumbs';
import SPCommitDetailsContextProvider from './context/SPCommitDetailsContextProvider';
import SPCommitDetailsTopLevelContainerForm from './SPCommitDetailsTopLevelContainerForm';

const SPCommitDetailsTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPCommitDetailsContextProvider>
            <SPCommitDetailsBreadcrumbs />
            <SPCommitDetailsTopLevelContainerForm />
          </SPCommitDetailsContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};

export default SPCommitDetailsTopLevelContainer;
