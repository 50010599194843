// @flow
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { If } from '@presentational/If';
import { getLinkForStreamingProjectsHomePage } from '@streaming-projects/links';
import { UserContext } from '@src/contexts/UserContext';
import { THREE_DOT_O_EARLY_ACCESS_LIST } from '@src/constants';

import { getLinkForHomePage } from '../links/utils';

import { EstimatesViewLink } from './EstimatesViewLink';
import { StreamingProjectsViewLink } from './StreamingProjectsViewLink';

export const ViewTogglerLink = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { userEmail } = useContext(UserContext);

  return (
    <>
      <If test={currentPath === getLinkForStreamingProjectsHomePage()}>
        <EstimatesViewLink />
      </If>

      <If
        test={
          currentPath === getLinkForHomePage() && THREE_DOT_O_EARLY_ACCESS_LIST.includes(userEmail)
        }
      >
        <StreamingProjectsViewLink />
      </If>
    </>
  );
};
